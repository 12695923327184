import { Component, OnInit } from '@angular/core';
import { IClient } from '../../models/dbModels/IClient';
import { EmployeeTitle, Gender, Relationship, BusinessType } from '../../general/enums';
import { Store } from '@ngrx/store';
import { IAppStore } from '../../state/IAppStore';
import { Observable } from 'rxjs';
import { IUser } from '../../models/DbModels/IUser';
import { isBusiness } from '../../state/actions/business/business-actions';
import { ClientService } from '../../services/client/client-service';
import { clientSetClients, clientAddClient, clientUpdate, clientSelect } from '../../state/actions/client/client-actions';
import { IBusiness } from '../../models/dbModels/IBusiness';
import { UserService } from '../../services/user/user-service';
import { usersUpdate } from '../../state/actions/user-actions';
import { Router } from '@angular/router';
import { AppStateService } from '../../services/state/app-state-service';
import { IUserAppState } from '../../models/state-model/IUserAppState';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  public newClient: boolean;
  public allowToSave: boolean;

  public user: IUser;
  public currentClient: IClient;
  public emptyClient: IClient;
  public business: IBusiness;
  public appState: IAppStore;

  public currentClient$: Observable<IClient>;
  public clients$: Observable<IClient[]>;
  public currentUser$: Observable<IUser>;
  public IsBusiness$: Observable<boolean>;
  public business$: Observable<IBusiness>;

  constructor(private store: Store<{ store: IAppStore }>, private userService: UserService,
    private clientService: ClientService, private route: Router, private appService: AppStateService) {
    this.currentClient$ = store.select(x => x.store.currentClient);
    this.clients$ = store.select(x => x.store.clients);
    this.currentUser$ = store.select(x => x.store.currentUser);
    this.IsBusiness$ = this.store.select(x => x.store.isBusiness);
    this.business$ = this.store.select(x => x.store.business);
  }

  ngOnInit(): void {
    this.init();
    this.allowToSave = false;
    this.business$.subscribe(business => {
      this.business = { ...business };
    });

    this.userService.getUsers(0, 10).subscribe(usersResult => {
      let usersRes: any = usersResult;
      if (usersRes.success) {
        this.store.dispatch(usersUpdate({ payload: { users: usersRes.item } }));
      }
    });

    this.currentUser$.subscribe(user => {
      this.user = user;
      if (user.employee.title !== EmployeeTitle.Employee) {
        this.clientService.getClients(0, 10).subscribe(res => {
          let result: any = res;

          if (result.success) {
            console.log(result.item);
            this.store.dispatch(clientSetClients({ payload: { clients: result.item } }));
          }
        });
      } else {
        this.clientService.getClientsByUser(localStorage.getItem('userId'), 0, 10).subscribe(res => {
          let result: any = res;

          if (result.success) {
            console.log(result.item);
            this.store.dispatch(clientSetClients({ payload: { clients: result.item } }));
          }
        });
      }
    });

    this.newClient = false;

    this.currentClient$.subscribe(client => {
      this.currentClient = { ...client };
      this.allowToSave = client.producer.userId != '' && client.csr.userId != '' && client.firstName != '' && client.lastName != '' && client.phone != '' && client.email != '' && client.dob != null && client.residenceAddress.street != '' && client.residenceAddress.city != '' && client.residenceAddress.state != '' && client.residenceAddress.zip != '';
    });

    this.store.select(x => x.store).subscribe(store => {
      this.appState = store;
    });


    this.store.dispatch(clientUpdate({ payload: { client: this.emptyClient } }));
  }

  public addNewBusinessClient() {
    this.store.dispatch(isBusiness({ payload: { isBusiness: true } }));
  }

  public addNewClient() {
    this.newClient = true;
  }

  public cancel() {
    this.newClient = false;
    this.store.dispatch(isBusiness({ payload: { isBusiness: false } }));
  }


  public save() {
    this.currentClient.business = this.business;
    this.clientService.saveNewClient(this.currentClient).subscribe(res => {
      let result: any = res;

      if (result.success) {
        this.cancel();
        this.store.dispatch(clientAddClient({ payload: { client: result.item } }));
        this.store.dispatch(clientUpdate({ payload: { client: this.emptyClient } }));
      }

    });
  }

  public next() {
    this.newClient = true;
    this.store.dispatch(isBusiness({ payload: { isBusiness: false } }));
  }

  public getScreenHeight() {
    return (screen.height - 200) + 'px';
  }

  public selectClientToView(selectedClientIndex) {
    this.store.dispatch(clientSelect({ payload: { clientIndex: selectedClientIndex } }));
    let newState: IUserAppState = { user: this.user, store: JSON.stringify(this.appState), userAppStateId: '', loggedInDate: new Date() };
    this.appService.updateState(newState).subscribe(update => {
      let updateResult: any = update;
      if (updateResult.success) {
        console.log('update');
        this.route.navigate(['/client']);
      } else {
        alert(updateResult.error);
      }
    });
  }

  //event handlers
  public selectBusinessEinChange(event) {

  }

  public selectBusinesNameChange(event) {

  }

  private init() {
    this.currentClient = {
      clientId: '',
      firstName: '',
      lastName: '',
      dob: null,
      phone: '',
      email: '',
      business: {
        businessAddress: {
          city: '',
          state: '',
          street: '',
          zip: ''
        },
        businessId: '',
        businessName: '',
        businessType: BusinessType.Corp,
        ein: '',
        mailingAddress: {
          city: '',
          state: '',
          street: '',
          zip: ''
        }
      },
      mailingAddress: {
        street: '', city: '',
        state: '', zip: ''
      },
      residenceAddress: {
        street: '', city: '',
        state: '', zip: ''
      },
      driverLic: '',
      gender: Gender.Male,
      occupation: '',
      relationship: Relationship.Insured,
      ssn: '',
      producer:
      {
        userId: '',
        email: '',
        employee: {
          employeeId: '',
          firstName: '',
          lastName: '',
          title: EmployeeTitle.Owner,
          notes: '',
          phone: '',
          birthDate: null,
          hireDate: null,
          isCurrentlyEmployed: true,
          picture: ''
        },
        IsDeleted: false,
        isLocked: false,
        IsSuspended: false,
        password: '',
        createdDate: null,
        modifiedDate: null
      },
      csr:
      {
        userId: '',
        email: '',
        employee: {
          employeeId: '',
          firstName: '',
          lastName: '',
          title: EmployeeTitle.Owner,
          notes: '',
          phone: '',
          birthDate: null,
          hireDate: null,
          isCurrentlyEmployed: true,
          picture: ''
        },
        IsDeleted: false,
        isLocked: false,
        IsSuspended: false,
        password: '',
        createdDate: null,
        modifiedDate: null
      },
      additionalInsureds:
        [
          {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            driverLic: '',
            gender: Gender.Male,
            occupation: '',
            relationship: Relationship.Insured,
            ssn: ''
          }
        ],
      source: '',
      contacts: [],
      created: null,
      deleted: null,
      modified: null
    };

    this.business = {
      businessAddress: {
        city: '',
        state: '',
        street: '',
        zip: ''
      },
      businessId: '',
      businessName: '',
      businessType: BusinessType.Corp,
      ein: '',
      mailingAddress: {
        city: '',
        state: '',
        street: '',
        zip: ''
      }
    };

    this.emptyClient = {
      clientId: '',
      firstName: '',
      lastName: '',
      dob: null,
      phone: '',
      email: '',
      business: {
        businessAddress: {
          city: '',
          state: '',
          street: '',
          zip: ''
        },
        businessId: '',
        businessName: '',
        businessType: BusinessType.Corp,
        ein: '',
        mailingAddress: {
          city: '',
          state: '',
          street: '',
          zip: ''
        }
      },
      mailingAddress: {
        street: '', city: '',
        state: '', zip: ''
      },
      residenceAddress: {
        street: '', city: '',
        state: '', zip: ''
      },
      driverLic: '',
      gender: Gender.Male,
      occupation: '',
      relationship: Relationship.Insured,
      ssn: '',
      producer:
      {
        userId: '',
        email: '',
        employee: {
          employeeId: '',
          firstName: '',
          lastName: '',
          title: EmployeeTitle.Owner,
          notes: '',
          phone: '',
          birthDate: null,
          hireDate: null,
          isCurrentlyEmployed: true,
          picture: ''
        },
        IsDeleted: false,
        isLocked: false,
        IsSuspended: false,
        password: '',
        createdDate: null,
        modifiedDate: null
      },
      csr:
      {
        userId: '',
        email: '',
        employee: {
          employeeId: '',
          firstName: '',
          lastName: '',
          title: EmployeeTitle.Owner,
          notes: '',
          phone: '',
          birthDate: null,
          hireDate: null,
          isCurrentlyEmployed: true,
          picture: ''
        },
        IsDeleted: false,
        isLocked: false,
        IsSuspended: false,
        password: '',
        createdDate: null,
        modifiedDate: null
      },
      additionalInsureds:
        [
          //{
          //  firstName: '',
          //  lastName: '',
          //  phone: '',
          //  email: '',
          //  address: '',
          //  city: '',
          //  state: '',
          //  zip: '',
          //  driverLic: '',
          //  gender: Gender.Male,
          //  occupation: '',
          //  relationship: Relationship.Insured,
          //  ssn: ''
          //}
        ],
      source: '',
      contacts: [],
      created: new Date(),
      deleted: null,
      modified: null
    };
  }
}
