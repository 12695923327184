import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { STATES, RELATIONSHIPS } from '../../../general/contants';
import { Store } from '@ngrx/store';
import { IAppStore } from '../../../state/IAppStore';
import { IClient } from '../../../models/dbModels/IClient';
import { Gender, Relationship, EmployeeTitle, BusinessType } from '../../../general/enums';
import { clientUpdateFName, clientUpdateRelationship, clientUpdateGender, clientUpdateLName, clientUpdatePhone, clientUpdateEmail, clientUpdateOccupation, clientUpdateSsn, clientUpdateDriverLicense, clientUpdateProducer, clientUpdateCsr, clientUpdateSource, clientUpdateAdditionalInsureds, clientAdditionalInsuredUpdateOccupation, clientAdditionalInsuredUpdateSsn, clientAdditionalInsuredUpdateDriverLicense, clientAdditionalInsuredUpdateRelationship, clientAdditionalInsuredUpdateGender, clientAdditionalInsuredUpdateZip, clientAdditionalInsuredUpdateState, clientAdditionalInsuredUpdateCity, clientAdditionalInsuredUpdateAddress, clientAdditionalInsuredUpdateEmail, clientAdditionalInsuredUpdatePhone, clientAdditionalInsuredUpdateLName, clientAdditionalInsuredUpdateFName, clientUpdateAdditionalInsured, clientUpdateResidentialStreet, clientUpdateResidentialCity, clientUpdateResidentialState, clientUpdateResidentialZip, clientUpdateMailingStreet, clientUpdateMailingCity, clientUpdateMailingState, clientUpdateMailingZip, clientAddContact, clientRemoveContact, clientUpdateMailing, clientAdditionalInsuredDob, clientUpdateDob, clientAddAdditionalInsured, clientAddClient, clientSelect, clientUpdate } from '../../../state/actions/client/client-actions';
import { IUser } from '../../../models/DbModels/IUser';
import { IAdditionalInsured } from '../../../models/supportive-models/IAdditionalInsured';
import { Observable } from 'rxjs';
import { IBusiness } from '../../../models/dbModels/IBusiness';

//icons from font awesome
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { updateBusinessName, updateBusinessEin, updateBusinessType, updateBusinessStreet, updateBusinessCity, updateBusinessState, updateBusinessZip, updateBusinessMailingStreet, updateBusinessMailingCity, updateBusinessMailingState, updateBusinessMailingZip, updateBusinessMailing } from '../../../state/actions/business/business-actions';
import { IAddress } from '../../../models/supportive-models/IAddress';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IContact } from '../../../models/supportive-models/IContact';

@Component({
  selector: 'app-client-new',
  templateUrl: './client-new.component.html',
  styleUrls: ['./client-new.component.css']
})
export class ClientNewComponent implements OnInit {

  public states: string[];
  public insuredName: string[];

  public currentInsuredIndex: number;

  public showClient: boolean;
  public showInsuredSnn: boolean;
  public showAdditionalInsuredSnn: boolean[];
  public hasMailingAddress: boolean;
  public hasMailingBusinessAddress: boolean;
  public residenceSameAsMailingAddress: boolean;
  public businessSameAsMailingAddress: boolean;
  public showNewContact: boolean;

  //public users: IUser[];
  public relationships: Relationship[];
  public client: IClient;
  public currentInsuredViewing: IAdditionalInsured;
  public business: IBusiness;
  public tempAddressHolder: IAddress;
  public tempBusinessAddressHolder: IAddress;
  public newContact: IContact;
  public options: BusinessType[];

  public GenderType: typeof Gender = Gender;
  public RelationshipType: typeof Relationship = Relationship;
  public BusinessType: typeof BusinessType = BusinessType;

  public IsBusiness$: Observable<boolean>;
  public clientContacts$: Observable<IContact[]>;
  public users$: Observable<IUser[]>;

  //font awesome
  faEye = faEye;

  //model ref
  // @ViewChild('closeBtnModal') closeBtnModal;

  constructor(private store: Store<{ store: IAppStore }>, config: NgbModalConfig, private modalService: NgbModal) {
    this.options = [BusinessType.Corp, BusinessType.LLC, BusinessType.Partnership, BusinessType.SoleProprietor];
    this.createEmptyObjects();
    this.insuredName = [];
    //this.users = [];
    this.showAdditionalInsuredSnn = [];
    this.IsBusiness$ = store.select(x => x.store.isBusiness);
    this.clientContacts$ = store.select(x => x.store.currentClient.contacts);
    this.users$ = store.select(x => x.store.users);

    //modal settings
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.showInsuredSnn = true;
    this.hasMailingAddress = false;
    this.showNewContact = false;
    this.hasMailingBusinessAddress = false;
    this.residenceSameAsMailingAddress = true;
    this.businessSameAsMailingAddress = true;
    this.showClient = true;
    this.states = STATES;
    this.relationships = RELATIONSHIPS;
    //this.userService.getUsers(0, 10).subscribe(usersResult => {
    //  let usersRes: any = usersResult;
    //  console.log(usersRes);
    //  if (usersRes.success) {
    //    this.users = [...usersRes.item];
    //    this.store.dispatch(usersUpdate({ payload: { users: usersRes.item} }));
    //  }
    //});
    this.tempAddressHolder = { street: '', city: '', state: '', zip: '' };
    this.tempBusinessAddressHolder = { street: '', city: '', state: '', zip: '' };

   // this.store.dispatch(clientUpdate({ payload: { client: this.client } }));
  }
  //methods
  public removeAdditinalInsured(insuredIndex: number) {
    console.log('pressed removed' + insuredIndex);
  }
  public changeToClient() {
    this.showClient = true;
    this.currentInsuredIndex = -1;
  }
  public changeInsured(insuredIndex: number) {
    this.currentInsuredIndex = insuredIndex;
    this.showClient = false;
    this.currentInsuredViewing = this.client.additionalInsureds[insuredIndex];
  }
  public addNewInsured() {
    this.insuredName.push('New Insured');
    this.currentInsuredViewing = {
      ... {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        gender: Gender.Male,
        relationship: Relationship.Insured,
        occupation: '',
        ssn: '',
        driverLic: ''
      }
    };

    this.showAdditionalInsuredSnn[this.currentInsuredIndex] = true;
    this.client.additionalInsureds.push(this.currentInsuredViewing);
    this.showClient = false;

    this.store.dispatch(clientAddAdditionalInsured({ payload: { additionalInsureds: { ...this.currentInsuredViewing } } }));

    if (this.currentInsuredIndex === undefined) {
      this.currentInsuredIndex = 0;
    } else {
      this.currentInsuredIndex++;
    }
  }

  public toggleClientsSsnVisibility() {
    this.showInsuredSnn = !this.showInsuredSnn;
  }
  public toggleInsuredPasswordVisibility() {
    this.showAdditionalInsuredSnn[this.currentInsuredIndex] = !this.showAdditionalInsuredSnn[this.currentInsuredIndex];
  }
  //redux property changes
  /*****************Client******************************************/
  public selectFirstNameChange(event) {
    this.client.firstName = event;
    this.store.dispatch(clientUpdateFName({ payload: { firstName: event } }));
  }

  public selectLastNameChange(event) {
    this.client.lastName = event;
    this.store.dispatch(clientUpdateLName({ payload: { lastName: event } }));
  }

  public selectPhoneChange(event) {
    this.client.phone = event;
    if (this.client.phone.length === 3) { this.client.phone += '-'; }
    if (this.client.phone.length === 7) { this.client.phone += '-'; }
    this.store.dispatch(clientUpdatePhone({ payload: { phone: event } }));
  }

  public selectEmailChange(event) {
    this.client.email = event;
    this.store.dispatch(clientUpdateEmail({ payload: { email: event } }));
  }

  //Client residential address
  public selectResidenceStreetChange(event) {
    this.client.residenceAddress.street = event;
    this.store.dispatch(clientUpdateResidentialStreet({ payload: { street: event } }));
    if (this.residenceSameAsMailingAddress) {
      this.client.mailingAddress.street = event;
      this.store.dispatch(clientUpdateMailingStreet({ payload: { street: event } }));
    }
  }

  public selectResidenceCityChange(event) {
    this.client.residenceAddress.city = event;
    this.store.dispatch(clientUpdateResidentialCity({ payload: { city: event } }));
    if (this.residenceSameAsMailingAddress) {
      this.client.mailingAddress.city = event;
      this.store.dispatch(clientUpdateMailingCity({ payload: { city: event } }));
    }
  }

  public selectResidenceStateChange(event) {
    this.client.residenceAddress.state = event;
    this.store.dispatch(clientUpdateResidentialState({ payload: { state: event } }));
    if (this.residenceSameAsMailingAddress) {
      this.client.mailingAddress.state = event;
      this.store.dispatch(clientUpdateMailingState({ payload: { state: event } }));
    }
  }

  public selectResidenceZipChange(event) {
    this.client.residenceAddress.zip = event;
    this.store.dispatch(clientUpdateResidentialZip({ payload: { zip: event } }));
    if (this.residenceSameAsMailingAddress) {
      this.client.mailingAddress.zip = event;
      this.store.dispatch(clientUpdateMailingZip({ payload: { zip: event } }));
    }
  }

  //client mailing address
  public selectMailingStreetChange(event) {
    this.client.mailingAddress.street = event;
    this.store.dispatch(clientUpdateMailingStreet({ payload: { street: event } }));
  }

  public selectMailingCityChange(event) {
    this.client.mailingAddress.city = event;
    this.store.dispatch(clientUpdateMailingCity({ payload: { city: event } }));
  }

  public selectMailingStateChange(event) {
    this.client.mailingAddress.state = event;
    this.store.dispatch(clientUpdateMailingState({ payload: { state: event } }));
  }

  public selectMailingZipChange(event) {
    this.client.mailingAddress.zip = event;
    this.store.dispatch(clientUpdateMailingZip({ payload: { zip: event } }));
  }

  //client other info
  public selectGenderChange(gender) {
    this.client.gender = gender;
    this.store.dispatch(clientUpdateGender({ payload: { gender: gender } }));
  }

  public selectInsuredDobChange(event) {
    this.client.dob = event;
    this.store.dispatch(clientUpdateDob({ payload: { dob: event } }));
  }

  public selectRelationshipChange(event) {
    this.client.relationship = event;
    this.store.dispatch(clientUpdateRelationship({ payload: { relationship: event } }));
  }

  public selectOccupationChange(event) {
    this.client.occupation = event;
    this.store.dispatch(clientUpdateOccupation({ payload: { occupation: event } }));
  }

  public selectSsnChange(event) {
    this.client.ssn = event;
    if (this.client.ssn.length === 3) { this.client.ssn += '-'; }
    if (this.client.ssn.length === 6) { this.client.ssn += '-'; }
    this.store.dispatch(clientUpdateSsn({ payload: { ssn: event } }));
  }


  public selectDriverLicenseChange(event) {
    this.client.driverLic = event;
    this.store.dispatch(clientUpdateDriverLicense({ payload: { driverLicense: event } }));
  }

  public selectProducerChange(event) {
    this.client.producer = event;
    this.store.dispatch(clientUpdateProducer({ payload: { producer: event } }));
  }

  public selectCsrChange(event) {
    this.client.csr = event;
    this.store.dispatch(clientUpdateCsr({ payload: { csr: event } }));
  }

  public selectSourceChange(event) {
    this.client.source = event;
    this.store.dispatch(clientUpdateSource({ payload: { source: event } }));
  }

  public selectAdditionalInsuredChange(event) {
    this.client.additionalInsureds = event;
    this.store.dispatch(clientUpdateAdditionalInsureds({ payload: { additionalInsureds: event } }));
  }

  //additinal insureds
  public selectAdditionalInsuredFirstNameChange(event) {
    console.log(this.currentInsuredIndex);
    console.log(event);
    this.currentInsuredViewing.firstName = event;
    this.insuredName[this.currentInsuredIndex] = event;
    this.store.dispatch(clientAdditionalInsuredUpdateFName({ payload: { index: this.currentInsuredIndex, firstName: event } }));
  }

  public selectAdditionalInsuredLastNameChange(event) {
    this.currentInsuredViewing.lastName = event;
    this.store.dispatch(clientAdditionalInsuredUpdateLName({ payload: { index: this.currentInsuredIndex, lastName: event } }));
  }

  public selectAdditionalInsuredPhoneChange(event) {
    this.currentInsuredViewing.phone = event;

    if (this.currentInsuredViewing.phone.length === 3) { this.currentInsuredViewing.phone += '-'; }
    if (this.currentInsuredViewing.phone.length === 7) { this.currentInsuredViewing.phone += '-'; }
    this.store.dispatch(clientAdditionalInsuredUpdatePhone({ payload: { index: this.currentInsuredIndex, phone: event } }));
  }

  public selectAdditionalInsuredEmailChange(event) {
    this.currentInsuredViewing.email = event;
    this.store.dispatch(clientAdditionalInsuredUpdateEmail({ payload: { index: this.currentInsuredIndex, email: event } }));
  }

  public selectAdditionalInsuredAddressChange(event) {
    this.currentInsuredViewing.address = event;
    this.store.dispatch(clientAdditionalInsuredUpdateAddress({ payload: { index: this.currentInsuredIndex, address: event } }));
  }

  public selectAdditionalInsuredCityChange(event) {
    this.currentInsuredViewing.city = event;
    this.store.dispatch(clientAdditionalInsuredUpdateCity({ payload: { index: this.currentInsuredIndex, city: event } }));
  }

  public selectAdditionalInsuredStateChange(event) {
    this.currentInsuredViewing.state = event;
    this.store.dispatch(clientAdditionalInsuredUpdateState({ payload: { index: this.currentInsuredIndex, state: event } }));
  }

  public selectAdditionalInsuredZipChange(event) {
    this.currentInsuredViewing.zip = event;
    this.store.dispatch(clientAdditionalInsuredUpdateZip({ payload: { index: this.currentInsuredIndex, zip: event } }));
  }

  public selectAdditionalInsuredGenderChange(gender) {
    this.currentInsuredViewing.gender = gender;
    this.store.dispatch(clientAdditionalInsuredUpdateGender({ payload: { index: this.currentInsuredIndex, gender: gender } }));
  }

  public selectAdditionalInsuredRelationshipChange(event) {
    this.currentInsuredViewing.relationship = +event;
    console.log(this.currentInsuredViewing.relationship);
    this.store.dispatch(clientAdditionalInsuredUpdateRelationship({ payload: { index: this.currentInsuredIndex, relationship: +event } }));
  }

  public selectAdditionalInsuredOccupationChange(event) {
    this.currentInsuredViewing.occupation = event;
    this.store.dispatch(clientAdditionalInsuredUpdateOccupation({ payload: { index: this.currentInsuredIndex, occupation: event } }));
  }

  public selectAdditionalInsuredSsnChange(event) {
    this.currentInsuredViewing.ssn = event;
    if (this.currentInsuredViewing.ssn.length === 3) { this.currentInsuredViewing.ssn += '-'; }
    if (this.currentInsuredViewing.ssn.length === 6) { this.currentInsuredViewing.ssn += '-'; }
    this.store.dispatch(clientAdditionalInsuredUpdateSsn({ payload: { index: this.currentInsuredIndex, ssn: event } }));
  }

  public selectAdditionalInsuredDriverLicenseChange(event) {
    this.currentInsuredViewing.driverLic = event;
    this.store.dispatch(clientAdditionalInsuredUpdateDriverLicense({ payload: { index: this.currentInsuredIndex, driverLicense: event } }));
  }

  public selectAdditionalInsuredDobChange(event) {
    this.currentInsuredViewing.dob = event;
    this.store.dispatch(clientAdditionalInsuredDob({ payload: { index: this.currentInsuredIndex, dob: event } }));
  }

  /*****************Business******************************************/

  public selectBusinesNameChange(event) {
    this.business.businessName = event;
    this.store.dispatch(updateBusinessName({ payload: { name: event } }));
  }
  public selectBusinessEinChange(event) {
    this.business.ein = event;
    this.store.dispatch(updateBusinessEin({ payload: { ein: event } }));
  }
  public selectBusinesTypeChange(event) {
    this.business.businessType = +event;
    this.store.dispatch(updateBusinessType({ payload: { type: +event } }));
  }
  //business address
  public selectBusinessStreetChange(event) {
    this.business.businessAddress.street = event;
    this.store.dispatch(updateBusinessStreet({ payload: { street: event } }));
    if (this.businessSameAsMailingAddress) {
      this.business.mailingAddress.street = event;
      this.store.dispatch(updateBusinessMailingStreet({ payload: { mailingStreet: event } }));
    }
  }
  public selectBusinessCityChange(event) {
    this.business.businessAddress.city = event;
    this.store.dispatch(updateBusinessCity({ payload: { city: event } }));
    if (this.businessSameAsMailingAddress) {
      this.business.mailingAddress.city = event;
      this.store.dispatch(updateBusinessMailingCity({ payload: { mailingCity: event } }));
    }
  }
  public selectBusinessStateChange(event) {
    this.business.businessAddress.state = event;
    this.store.dispatch(updateBusinessState({ payload: { state: event } }));
    if (this.businessSameAsMailingAddress) {
      this.business.mailingAddress.state = event;
      this.store.dispatch(updateBusinessMailingState({ payload: { mailingState: event } }));
    }
  }
  public selectBusinessZipChange(event) {
    this.business.businessAddress.zip = event;
    this.store.dispatch(updateBusinessZip({ payload: { zip: event } }));
    if (this.businessSameAsMailingAddress) {
      this.business.mailingAddress.zip = event;
      this.store.dispatch(updateBusinessMailingZip({ payload: { mailingZip: event } }));
    }
  }
  //mailing business address
  public selectBusinessMailingStreetChange(event) {
    this.business.mailingAddress.street = event;
    this.store.dispatch(updateBusinessMailingStreet({ payload: { mailingStreet: event } }));
  }
  public selectBusinessMailingCityChange(event) {
    this.business.mailingAddress.city = event;
    this.store.dispatch(updateBusinessMailingCity({ payload: { mailingCity: event } }));
  }
  public selectBusinessMailingStateChange(event) {
    this.business.mailingAddress.state = event;
    this.store.dispatch(updateBusinessMailingState({ payload: { mailingState: event } }));
  }
  public selectBusinessMailingZipChange(event) {
    this.business.mailingAddress.zip = event;
    this.store.dispatch(updateBusinessMailingZip({ payload: { mailingZip: event } }));
  }

  //helping methods
  private createEmptyObjects() {
    this.client =
    {
      clientId: '',
      firstName: '',
      lastName: '',
      dob: null,
      phone: '',
      email: '',
      business: {
        businessAddress: {
          city: '',
          state: '',
          street: '',
          zip: ''
        },
        businessId: '',
        businessName: '',
        businessType: BusinessType.Corp,
        ein: '',
        mailingAddress: {
          city: '',
          state: '',
          street: '',
          zip: ''
        }
      },
      residenceAddress: {
        street: '', city: '',
        state: '', zip: ''
      },
      mailingAddress: {
        street: '', city: '',
        state: '', zip: ''
      },
      driverLic: '',
      gender: Gender.Male,
      occupation: '',
      relationship: Relationship.Insured,
      ssn: '',
      producer:
      {
        userId: '',
        email: '',
        employee: {
          employeeId: '',
          firstName: '',
          lastName: '',
          title: EmployeeTitle.Owner,
          notes: '',
          phone: '',
          birthDate: null,
          hireDate: null,
          isCurrentlyEmployed: true,
          picture: ''
        },
        IsDeleted: false,
        isLocked: false,
        IsSuspended: false,
        password: '',
        createdDate: null,
        modifiedDate: null
      },
      csr:
      {
        userId: '',
        email: '',
        employee: {
          employeeId: '',
          firstName: '',
          lastName: '',
          title: EmployeeTitle.Owner,
          notes: '',
          phone: '',
          birthDate: null,
          hireDate: null,
          isCurrentlyEmployed: true,
          picture: ''
        },
        IsDeleted: false,
        isLocked: false,
        IsSuspended: false,
        password: '',
        createdDate: null,
        modifiedDate: null
      },
      additionalInsureds: [],
      source: '',
      contacts: [],
      created: new Date(),
      deleted: null,
      modified: null
    };

    this.currentInsuredViewing = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      gender: Gender.Male,
      relationship: Relationship.Insured,
      occupation: '',
      ssn: '',
      driverLic: ''
    };

    this.business = {
      businessId: '',
      businessName: '',
      businessType: BusinessType.Corp,
      ein: '',
      businessAddress: {
        street: '', city: '',
        state: '', zip: ''
      },
      mailingAddress: {
        street: '', city: '',
        state: '', zip: ''
      }
    }

    this.newContact = { email: '', firstName: '', lastName: '', phone: '', title: '' }
  }

  public mailingDifferentthanBilling() {
    this.residenceSameAsMailingAddress = !this.residenceSameAsMailingAddress;
    if (this.residenceSameAsMailingAddress) {
      this.tempAddressHolder = { ...this.client.mailingAddress };
      this.client.mailingAddress = { ...this.client.residenceAddress };
      this.store.dispatch(clientUpdateMailing({ payload: { mailingAddress: this.client.residenceAddress } }));
    } else {
      this.client.mailingAddress = { ...this.tempAddressHolder };
      this.store.dispatch(clientUpdateMailing({ payload: { mailingAddress: this.tempAddressHolder } }));
    }
  }

  public mailingDifferentthanBillingBusiness() {
    this.businessSameAsMailingAddress = !this.businessSameAsMailingAddress;
    if (this.businessSameAsMailingAddress) {
      this.tempBusinessAddressHolder = { ...this.business.mailingAddress };
      this.business.mailingAddress = { ...this.business.businessAddress };
      this.store.dispatch(updateBusinessMailing({ payload: { mailingAddress: this.business.businessAddress } }));
    } else {
      this.business.mailingAddress = { ...this.tempBusinessAddressHolder };
      this.store.dispatch(updateBusinessMailing({ payload: { mailingAddress: this.tempBusinessAddressHolder } }));
    }
  }

  public addNewContact(content) {
    this.modalService.open(content, { size: 'xl' });
    this.showNewContact = !this.showNewContact;
  }

  public saveNewContact() {
    this.store.dispatch(clientAddContact({ payload: { newContact: this.newContact } }));
    this.newContact = { email: '', firstName: '', lastName: '', phone: '', title: '' }
  }

  public removeContact(contactIndex) {
    this.store.dispatch(clientRemoveContact({ payload: { contactToRemoveIndex: contactIndex } }));
  }

  public clearContact() {
    this.newContact = { email: '', firstName: '', lastName: '', phone: '', title: '' }
  }
}
