import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { extend } from 'webdriver-js-extender';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { IAppStore } from '../../state/IAppStore';
import { IUser } from '../../models/DbModels/IUser';
import { ILoginCredentials } from '../../models/ILoginCredentials';
import { DEBUG } from '../../general/contants';
import { error } from 'protractor';

@Injectable()
export class AuthGuardService {

  public currentUser: IUser;
  public currentUser$: Observable<IUser>;

  constructor(private http: HttpClient, private router: Router, private store: Store<{ store: IAppStore }>) {
    store.select(x => x.store.currentUser).subscribe(x => this.currentUser = x).unsubscribe();
  }

  //This function make the validation call to the back end.
  public validateToken(): Observable<boolean> {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') !== null && localStorage.getItem('wcct') !== undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };

    return this.http.get(api + 'CredentialsValidator/validation', httpOptions).pipe(
      map(x => {
        console.log('auth here');
        var res: any = x;
        if (!res.success) {
          //if (localStorage.getItem('rmb') == 'true') {
          //  var tempUsrHolder = localStorage.getItem('usr');
          //  var tempRememberHolder = localStorage.getItem('rmb');
          //  localStorage.clear();
          //   localStorage.setItem('usr', tempUsrHolder);
          //   localStorage.setItem('rmb', tempRememberHolder);
          // } else {
          //   localStorage.clear();
          //}

          this.router.navigate(['/wc/admin/login']);
          return false;
        }
        return true;
      })      
    );
  }


}

//export class HttpConfig {
//  private token: string = 'DEGUBAndreWizard';//!DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('uet') != null && localStorage.getItem('uet') != undefined ? localStorage.getItem('uet') : '';
//  public apiECD: string = environment.apiECDHost + 'api/v1/';
//  public api: string = environment.apiHost + 'api/v1/';
//  public httpOptions = {
//    headers: new HttpHeaders(
//      {
//        'Content-Type': 'application/json',
//        'Authorization': this.token
//      }
//    ),
//    params: new HttpParams()
//  };;

//  constructor() { }
//}
