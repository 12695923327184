import { Component, OnInit } from '@angular/core';
import { IUser } from '../../models/DbModels/IUser';
import { EmployeeTitle } from '../../general/enums';
import { Store } from '@ngrx/store';
import { IAppStore } from '../../state/IAppStore';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../../services/user/user-service';
import { RegisterService } from '../../services/register-service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public newUser: boolean;
  public users: IUser[];

  public newUser$: Observable<IUser>;

  private newUserSubscription: Subscription;

  constructor(private store: Store<{ store: IAppStore }>, private registerService: RegisterService, private userService: UserService) {
    this.newUser$ = store.select(x => x.store.newUser);
  }

  ngOnInit(): void {
    this.newUser = false;

    this.users = []

    this.userService.getUsers(0,10).subscribe(users => {
      let usersResult: any = users;
      if (usersResult.success) {        
        this.users = [...usersResult.item];
      }
    });
  }

  ngOnDestroy() {
    if (this.newUserSubscription != undefined) { this.newUserSubscription.unsubscribe(); }
  }

  public addNewUser() {
    this.newUser = true;
  }

  public cancel() {
    this.newUser = false;
  }

  public save() {
    this.newUserSubscription = this.newUser$.subscribe(x => {
      console.log(x);
      this.registerService.registerNewUser(x).subscribe(res => {
        let newUserResult: any = res;
        if (newUserResult.success) {
          this.users = [...this.users, newUserResult.item];
          this.cancel();
        }
      });
    });
  }

  public getScreenHeight() {
    return (screen.height-200) + 'px';
  }
}
