import { createAction, props } from "@ngrx/store";
import { IClient } from "../../../models/dbModels/IClient";
import { IAddress } from "../../../models/supportive-models/IAddress";
import { BusinessType } from "../../../general/enums";

//Set isbusiness flag for visibility
export const isBusiness = createAction('[Update business flag] update business flag', props<{ payload: { isBusiness: boolean; } }>());
//update business info
export const updateBusinessName = createAction('[Update business name] update business name', props<{ payload: { name: string; } }>());
export const updateBusinessType = createAction('[Update business name] update business type', props<{ payload: { type: BusinessType; } }>());
export const updateBusinessEin = createAction('[Update business name] update business ein', props<{ payload: { ein: string; } }>());
export const updateBusinessClient = createAction('[Update business name] update business client', props<{ payload: { client: IClient; } }>());
export const updateBusinessStreet = createAction('[Update business name] update business street', props<{ payload: { street: string; } }>());
export const updateBusinessCity = createAction('[Update business name] update business city', props<{ payload: { city: string; } }>());
export const updateBusinessState = createAction('[Update business name] update business state', props<{ payload: { state: string; } }>());
export const updateBusinessZip = createAction('[Update business name] update business zip', props<{ payload: { zip: string; } }>());
export const updateBusinessMailingStreet = createAction('[Update business ein] update business mailing street', props<{ payload: { mailingStreet: string; } }>());
export const updateBusinessMailingCity = createAction('[Update business ein] update business mailign city', props<{ payload: { mailingCity: string; } }>());
export const updateBusinessMailingState = createAction('[Update business ein] update business mailing state', props<{ payload: { mailingState: string; } }>());
export const updateBusinessMailingZip = createAction('[Update business ein] update business mailing zip', props<{ payload: { mailingZip: string; } }>());
export const updateBusinessMailing = createAction('[Update business ein] update business mailing ', props<{ payload: { mailingAddress: IAddress; } }>());
