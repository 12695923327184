import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  public showChat: boolean;

  constructor() { }

  ngOnInit(): void {
    this.showChat = false;
  }

  public changeChatView() {
    console.log(this.showChat);
    this.showChat = !this.showChat;
  }
}
