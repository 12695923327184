import { Component, OnInit } from '@angular/core';
import { IClient } from '../../models/dbModels/IClient';
import { EmployeeTitle, Gender, Relationship } from '../../general/enums';

@Component({
  selector: 'app-prospects',
  templateUrl: './prospects.component.html',
  styleUrls: ['./prospects.component.css']
})
export class ProspectsComponent implements OnInit {

  public newProspect: boolean;
  public clients: IClient[];

  constructor() { }

  ngOnInit(): void {
    this.newProspect = false;
    this.clients = [];
  }

  public addNewClient() {
    this.newProspect = true;
  }

  public uploadNewClient(){

  }

  public cancel() {
    this.newProspect = false;
  }

  public save() { }

  public getScreenHeight() {
    return (screen.height - 200) + 'px';
  }



  private createEmptyObjects() {
    //this.client =
    //{
    //  firstName: '',
    //  lastName: '',
    //  phone: '',
    //  email: '',
    //  address: '',
    //  city: '',
    //  state: '',
    //  zip: '',
    //  driverLic: '',
    //  gender: Gender.Male,
    //  occupation: '',
    //  relationship: Relationship.Insured,
    //  ssn: '',
    //  producer:
    //  {
    //    userId: '',
    //    email: '',
    //    employee: {
    //      employeeId: '',
    //      firstName: '',
    //      lastName: '',
    //      title: EmployeeTitle.Owner,
    //      notes: '',
    //      phone: '',
    //      birthDate: null,
    //      hireDate: null,
    //      isCurrentlyEmployed: true,
    //      picture: ''
    //    },
    //    IsDeleted: false,
    //    isLocked: false,
    //    IsSuspended: false,
    //    password: '',
    //    createdDate: null,
    //    modifiedDate: null
    //  },
    //  csr:
    //  {
    //    userId: '',
    //    email: '',
    //    employee: {
    //      employeeId: '',
    //      firstName: '',
    //      lastName: '',
    //      title: EmployeeTitle.Owner,
    //      notes: '',
    //      phone: '',
    //      birthDate: null,
    //      hireDate: null,
    //      isCurrentlyEmployed: true,
    //      picture: ''
    //    },
    //    IsDeleted: false,
    //    isLocked: false,
    //    IsSuspended: false,
    //    password: '',
    //    createdDate: null,
    //    modifiedDate: null
    //  },
    //  additionalInsureds:
    //    [
    //      {
    //        firstName: '',
    //        lastName: '',
    //        address: '',
    //        city: '',
    //        state: '',
    //        zip: '',
    //        driverLic: '',
    //        gender: Gender.Male,
    //        occupation: '',
    //        relationship: Relationship.Insured,
    //        ssn: ''
    //      }
    //    ],
    //  source: ''
    //};
  }
}
