
export enum EmployeeTitle {
  None=-1,
  Owner = 0,
  Master = 1,
  Admin = 2,
  Employee = 3
}

export enum Gender {
  Male,
  Female
}

export enum Relationship {
  Insured,
  Spouse,
  Child,
  Parent,
  Sibling
}

export enum BusinessType {
  LLC,
  Corp,
  Partnership,
  SoleProprietor,
  Individual
}
