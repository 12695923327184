import { Store, createReducer, on } from '@ngrx/store';
import { userUpdateFName, userUpdateLName, userUpdateDOB, userUpdateEmail, userUpdatePhone, userUpdateTitle, userUpdateNote, userNewNote, userNewTitle, userNewEmail, userNewPhone, userNewDOB, userNewLName, userNewFName, userUpdate, userToken, usersUpdate } from './actions/user-actions';
import { IAppStore, resetAppState } from './IAppStore';
import { clientUpdate, clientUpdateFName, clientUpdateLName, clientUpdatePhone, clientUpdateEmail, clientUpdateGender, clientUpdateRelationship, clientUpdateOccupation, clientUpdateSsn, clientUpdateDriverLicense, clientUpdateProducer, clientUpdateCsr, clientUpdateSource, clientUpdateAdditionalInsureds, clientUpdateAdditionalInsured, clientAdditionalInsuredUpdateFName, clientAdditionalInsuredUpdateLName, clientAdditionalInsuredUpdatePhone, clientAdditionalInsuredUpdateEmail, clientAdditionalInsuredUpdateAddress, clientAdditionalInsuredUpdateCity, clientAdditionalInsuredUpdateState, clientAdditionalInsuredUpdateZip, clientAdditionalInsuredUpdateGender, clientAdditionalInsuredUpdateRelationship, clientAdditionalInsuredUpdateOccupation, clientAdditionalInsuredUpdateSsn, clientAdditionalInsuredUpdateDriverLicense, clientAddAdditionalInsured, clientUpdateResidentialStreet, clientUpdateResidentialCity, clientUpdateResidentialState, clientUpdateResidentialZip, clientUpdateMailingStreet, clientUpdateMailingCity, clientUpdateMailingState, clientUpdateMailingZip, clientAddContact, clientRemoveContact, clientUpdateMailing, clientAddClient, clientSetClients, clientAdditionalInsuredDob, clientUpdateDob, clientSelect } from './actions/client/client-actions';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { myInit, resetStore } from './actions/state-actions';
import { isBusiness, updateBusinessName, updateBusinessEin, updateBusinessType, updateBusinessClient, updateBusinessStreet, updateBusinessState, updateBusinessCity, updateBusinessZip, updateBusinessMailingStreet, updateBusinessMailingCity, updateBusinessMailingState, updateBusinessMailingZip, updateBusinessMailing } from './actions/business/business-actions';
import { addLog, loadingLogs, updateLogRemaiderCompleteDate } from './actions/client/logs/log-actions';

export class StateManager {
  //  static storeReducer: ActionReducer<unknown, Action>;

  constructor(private store: Store<{ store: IAppStore }>) { }

  ngOnInit() { }

  ngOnDestroy() { }

  public static storeReducer(state = resetAppState, action): IAppStore {
    let store: IAppStore;
    store = _storeReducer(state, action);
    return store;
  }
}

const _storeReducer = createReducer(resetAppState,
  /*********************************State Actions***********************************************/
  on(myInit, (state, { payload }) => ({ ...payload.store })),
  on(resetStore, (state) => ({ ...resetAppState })),
/*********************************User Actions***********************************************/
  //users
  on(usersUpdate, (state, { payload }) => ({ ...state, users: payload.users })),

  //update current user
  on(userUpdate, (state, { payload }) => ({ ...state, currentUser: payload.user })),
  on(userUpdateFName, (state, { payload }) => ({ ...state, currentUser: { ...state.currentUser, employee: { ...state.currentUser.employee, firstName: payload.fName } } })),
  on(userUpdateLName, (state, { payload }) => ({ ...state, currentUser: { ...state.currentUser, employee: { ...state.currentUser.employee, lastName: payload.lName } } })),
  on(userUpdateDOB, (state, { payload }) => ({ ...state, currentUser: { ...state.currentUser, employee: { ...state.currentUser.employee, birthDate: payload.DOB } } })),
  on(userUpdateEmail, (state, { payload }) => ({ ...state, currentUser: { ...state.currentUser, email: payload.email } })),
  on(userUpdatePhone, (state, { payload }) => ({ ...state, currentUser: { ...state.currentUser, employee: { ...state.currentUser.employee, phone: payload.phone } } })),
  on(userUpdateTitle, (state, { payload }) => ({ ...state, currentUser: { ...state.currentUser, employee: { ...state.currentUser.employee, title: payload.title } } })),
  on(userUpdateNote, (state, { payload }) => ({ ...state, currentUser: { ...state.currentUser, employee: { ...state.currentUser.employee, notes: payload.note } } })),
  //add new user
  on(userNewFName, (state, { payload }) => ({ ...state, newUser: { ...state.newUser, employee: { ...state.newUser.employee, firstName: payload.fName } } })),
  on(userNewLName, (state, { payload }) => ({ ...state, newUser: { ...state.newUser, employee: { ...state.newUser.employee, lastName: payload.lName } } })),
  on(userNewDOB, (state, { payload }) => ({ ...state, newUser: { ...state.newUser, employee: { ...state.newUser.employee, birthDate: payload.DOB } } })),
  on(userNewEmail, (state, { payload }) => ({ ...state, newUser: { ...state.newUser, email: payload.email } })),
  on(userNewPhone, (state, { payload }) => ({ ...state, newUser: { ...state.newUser, employee: { ...state.newUser.employee, phone: payload.phone } } })),
  on(userNewTitle, (state, { payload }) => ({ ...state, newUser: { ...state.newUser, employee: { ...state.newUser.employee, title: payload.title } } })),
  on(userNewNote, (state, { payload }) => ({ ...state, newUser: { ...state.newUser, employee: { ...state.newUser.employee, notes: payload.note } } })),
  //user token
  on(userToken, (state, { payload }) => ({ ...state, userToken: payload.token })),


  /*********************************Client Actions***********************************************/
  //select client
  on(clientSelect, (state, { payload }) => ({ ...state, currentClient: { ...state.clients.find((content, index) => { if (index === payload.clientIndex) { return content; } }) } })),
  //client list
  on(clientAddClient, (state, { payload }) => ({ ...state, clients: [...state.clients, payload.client] })),
  on(clientSetClients, (state, { payload }) => ({ ...state, clients: [...payload.clients] })),
  //update current client
  on(clientUpdate, (state, { payload }) => ({ ...state, currentClient: payload.client })),
  on(clientUpdateFName, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, firstName: payload.firstName } })),
  on(clientUpdateLName, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, lastName: payload.lastName } })),
  on(clientUpdatePhone, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, phone: payload.phone } })),
  on(clientUpdateEmail, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, email: payload.email } })),
  on(clientUpdateResidentialStreet, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, residenceAddress: { ...state.currentClient.residenceAddress, street: payload.street } } })),
  on(clientUpdateResidentialCity, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, residenceAddress: { ...state.currentClient.residenceAddress, city: payload.city } } })),
  on(clientUpdateResidentialState, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, residenceAddress: { ...state.currentClient.residenceAddress, state: payload.state } } })),
  on(clientUpdateResidentialZip, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, residenceAddress: { ...state.currentClient.residenceAddress, zip: payload.zip } } })),
  on(clientUpdateMailingStreet, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, mailingAddress: { ...state.currentClient.mailingAddress, street: payload.street } } })),
  on(clientUpdateMailingCity, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, mailingAddress: { ...state.currentClient.mailingAddress, city: payload.city } } })),
  on(clientUpdateMailingState, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, mailingAddress: { ...state.currentClient.mailingAddress, state: payload.state } } })),
  on(clientUpdateMailingZip, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, mailingAddress: { ...state.currentClient.mailingAddress, zip: payload.zip } } })),
  on(clientUpdateMailing, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, mailingAddress: payload.mailingAddress } })),
  on(clientUpdateGender, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, gender: payload.gender } })),
  on(clientUpdateRelationship, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, relationship: payload.relationship } })),
  on(clientUpdateOccupation, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, occupation: payload.occupation } })),
  on(clientUpdateSsn, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, ssn: payload.ssn } })),
  on(clientUpdateDriverLicense, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, driverLic: payload.driverLicense } })),
  on(clientUpdateProducer, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, producer: payload.producer } })),
  on(clientUpdateCsr, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, csr: payload.csr } })),
  on(clientUpdateSource, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, source: payload.source } })),
  on(clientUpdateAdditionalInsureds, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: payload.additionalInsureds } })),
  on(clientAddContact, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, contacts: [...state.currentClient.contacts, payload.newContact] } })),
  on(clientRemoveContact, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, contacts: [...state.currentClient.contacts.filter((content, index) => { if (index !== payload.contactToRemoveIndex) return content; })] } })),
  on(clientUpdateDob, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, dob: payload.dob } })),

  //add current client additional insured
  on(clientAddAdditionalInsured, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds, payload.additionalInsureds] } })),

  //update current client additional insureds
  on(clientUpdateAdditionalInsured, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: state.currentClient.additionalInsureds.map((content, i) => i === payload.index ? content = payload.additionalInsureds : content) } })),
  on(clientAdditionalInsuredUpdateFName, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, firstName: payload.firstName }; } return content; })] } })),// [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], firstName: payload.firstName}}}})),
  on(clientAdditionalInsuredUpdateLName, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, lastName: payload.lastName }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], lastName: payload.lastName }] }}})),
  on(clientAdditionalInsuredUpdatePhone, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, phone: payload.phone }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], phone: payload.phone  }] } } })),
  on(clientAdditionalInsuredUpdateEmail, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, email: payload.email }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], email: payload.email  }] } } })),
  on(clientAdditionalInsuredUpdateAddress, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) content = { ...content, address: payload.address }; return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], address: payload.address  }] } } })),
  on(clientAdditionalInsuredUpdateCity, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, city: payload.city }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], city: payload.city }] } } })),
  on(clientAdditionalInsuredUpdateState, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, state: payload.state }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], state: payload.state }] } } })),
  on(clientAdditionalInsuredUpdateZip, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, zip: payload.zip }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], zip: payload.zip } ]} } })),
  on(clientAdditionalInsuredUpdateGender, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, gender: payload.gender }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], gender: payload.gender } ]} } })),
  on(clientAdditionalInsuredUpdateRelationship, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) content = { ...content, relationship: payload.relationship }; return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], relationship: payload.relationship }] } } })),
  on(clientAdditionalInsuredUpdateOccupation, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, occupation: payload.occupation }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], occupation: payload.occupation }] } } })),
  on(clientAdditionalInsuredUpdateSsn, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, ssn: payload.ssn }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], ssn: payload.ssn }] } } } )),
  on(clientAdditionalInsuredUpdateDriverLicense, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, driverLic: payload.driverLicense }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], driverLic: payload.driverLicense }] } } })),
  on(clientAdditionalInsuredDob, (state, { payload }) => ({ ...state, currentClient: { ...state.currentClient, additionalInsureds: [...state.currentClient.additionalInsureds.map((content, index) => { if (index === payload.index) { content = { ...content, dob: payload.dob }; } return content; })] } })),//, [payload.index]: { ...state.currentClient.additionalInsureds[payload.index], driverLic: payload.driverLicense }] } } })),

  //business actions
  on(isBusiness, (state, { payload }) => ({ ...state, isBusiness: payload.isBusiness })),
  on(updateBusinessName, (state, { payload }) => ({ ...state, business: { ...state.business, businessName: payload.name } })),
  on(updateBusinessEin, (state, { payload }) => ({ ...state, business: { ...state.business, ein: payload.ein } })),
  on(updateBusinessType, (state, { payload }) => ({ ...state, business: { ...state.business, businessType: payload.type } })),
  on(updateBusinessClient, (state, { payload }) => ({ ...state, business: { ...state.business, client: payload.client } })),
  on(updateBusinessStreet, (state, { payload }) => ({ ...state, business: { ...state.business, businessAddress: { ...state.business.businessAddress, street: payload.street } } })),
  on(updateBusinessCity, (state, { payload }) => ({ ...state, business: { ...state.business, businessAddress: { ...state.business.businessAddress, city: payload.city } } })),
  on(updateBusinessState, (state, { payload }) => ({ ...state, business: { ...state.business, businessAddress: { ...state.business.businessAddress, state: payload.state } } })),
  on(updateBusinessZip, (state, { payload }) => ({ ...state, business: { ...state.business, businessAddress: { ...state.business.businessAddress, zip: payload.zip } } })),
  on(updateBusinessMailingStreet, (state, { payload }) => ({ ...state, business: { ...state.business, mailingAddress: { ...state.business.mailingAddress, street: payload.mailingStreet } } })),
  on(updateBusinessMailingCity, (state, { payload }) => ({ ...state, business: { ...state.business, mailingAddress: { ...state.business.mailingAddress, city: payload.mailingCity } } })),
  on(updateBusinessMailingState, (state, { payload }) => ({ ...state, business: { ...state.business, mailingAddress: { ...state.business.mailingAddress, state: payload.mailingState } } })),
  on(updateBusinessMailingZip, (state, { payload }) => ({ ...state, business: { ...state.business, mailingAddress: { ...state.business.mailingAddress, zip: payload.mailingZip } } })),
  on(updateBusinessMailing, (state, { payload }) => ({ ...state, business: { ...state.business, mailingAddress: payload.mailingAddress } })),

  //logs
  on(addLog, (state, { payload }) => ({ ...state, logs: [...state.logs, payload.newLog] })),
  on(loadingLogs, (state, { payload }) => ({ ...state, logs: [...payload.loadingLogs] })),
  on(updateLogRemaiderCompleteDate, (state, { payload }) => ({ ...state, logs: [...state.logs.map((content, index) => { if (content.logId === payload.logId) { content = { ...content, reminderCompletedDate: payload.completeDate }; } return content; })] })), 
);
