import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IClient } from '../../../models/dbModels/IClient';
import { Store } from '@ngrx/store';
import { IAppStore } from '../../../state/IAppStore';
import { ClientService } from '../../../services/client/client-service';
import { IUser } from '../../../models/DbModels/IUser';
import { Gender, Relationship, BusinessType } from '../../../general/enums';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { IUserAppState } from '../../../models/state-model/IUserAppState';
import { AppStateService } from '../../../services/state/app-state-service';
import { IAdditionalInsured } from '../../../models/supportive-models/IAdditionalInsured';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.css']
})
export class ClientViewComponent implements OnInit {

  public showInsuredSnn: boolean;
  public showAddInsured: boolean;
  public ssnHidden: string;
  public showExtaCard: boolean;
  public ssnTempHolder: string;
  public client: IClient;
  public selectedInsured: IAdditionalInsured;

  public currentClient$: Observable<IClient>;
  public users$: Observable<IUser[]>;

  private statesSubscription: Subscription;

  //font awesome
  faEye = faEye;

  public GenderType: typeof Gender = Gender;
  public RelationshipType: typeof Relationship = Relationship;
  public BusinessType: typeof BusinessType = BusinessType;

  constructor(private store: Store<{ store: IAppStore }>, config: NgbModalConfig, private clientService: ClientService, private appStateService: AppStateService) {
    this.currentClient$ = store.select(x => x.store.currentClient);
    this.users$ = store.select(x => x.store.users);
  }

  ngOnInit(): void {
    this.showInsuredSnn = false;
    this.showAddInsured = null;
    this.showExtaCard = false;
    this.ssnHidden = '*********';
    this.currentClient$.subscribe(client => {
      this.client = { ...client };



      this.ssnTempHolder = this.client.ssn;
      // this.client.ssn = this.ssnHidden;
    });

    this.selectedInsured = {
      address: '', city: '',
      driverLic: '', email: '',
      firstName: '', gender: Gender.Male,
      lastName: '', phone: '',
      occupation: '', relationship: Relationship.Child,
      ssn: '', state: '', zip: '', dob: new Date()
    };
  }

  public toggleClientsSsnVisibility() {
    this.showInsuredSnn = !this.showInsuredSnn;
    //if (!this.showInsuredSnn) {
    //  this.client.ssn = this.ssnHidden;
    //} else {
    //  this.client.ssn = this.ssnTempHolder;
    //}
  }

  public showExtraCardToggle() {
    this.showExtaCard = !this.showExtaCard;
  }

  public selectAdditionalInsured(addInsured: IAdditionalInsured) {
    if (this.showAddInsured === null) {
      this.showAddInsured = true;
      this.selectedInsured = { ...addInsured };
    } else {
      if (this.selectedInsured.firstName === addInsured.firstName) {
        this.showAddInsured = null;
      }
      else {
        //this.showAddInsured = !this.showAddInsured;
        this.selectedInsured = { ...addInsured };
      }
    }
  }
}
