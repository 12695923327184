//import { HttpConfig } from '../config/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IUserAppState } from '../../models/state-model/IUserAppState';
import { DEBUG } from '../../general/contants';
//import { ILoginCredentials } from '../../models/login/ILoginCredentials';
//import { IUserAppState } from '../../models/state-model/user-app-state';

@Injectable()
export class AppStateService {// extends HttpConfig {

  constructor(private http: HttpClient, private router: Router) { }

  public getState(appStateId: string) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };

    return this.http.get(api + 'AppState/byId/' + appStateId, httpOptions);
  }

  public saveState(userState: IUserAppState) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };
    return this.http.post(api + 'AppState', userState, httpOptions);
  }

  public updateState(userState: IUserAppState) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };
    return this.http.put(api + 'AppState', userState, httpOptions);
  }

  public deleteState(appStateId: string, userId: string) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };
    return this.http.delete(api + 'AppState/removeById/' + appStateId +'/'+userId, httpOptions);
  }
}
