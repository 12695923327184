import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { IUser } from '../../models/DbModels/IUser';
import { EmployeeTitle } from '../../general/enums';
import { Store } from '@ngrx/store';
import { IAppStore } from '../../state/IAppStore';
import { Observable, Subscription } from 'rxjs';
import { userUpdate } from '../../state/actions/user-actions';
import { AuthGuard } from '../../security/auth-guard';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  public user: IUser;

  public currentUser$: Observable<IUser>;

  private currentUserSubscription: Subscription;

  constructor(private route: Router, location: Location, private element: ElementRef, private router: Router, private store: Store<{ store: IAppStore }>, private authGuard: AuthGuard) {
    this.location = location;
    this.currentUser$ = store.select(x => x.store.currentUser);
  }

  ngOnInit() {

    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }
  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    return titlee.slice(1).toUpperCase();
   // return 'Dashboard';
  }
  public logout() {
    this.authGuard.logout();
  }


  private createEmptyObjects() {
    this.user =
    {
      userId: '',
      email: '',
      password: '',
      createdDate: null,
      employee: {
        employeeId: '',
        firstName: '',
        lastName: '',
        title: EmployeeTitle.None,
        notes: '',
        phone: '',
        birthDate: null,
        hireDate: null,
        isCurrentlyEmployed: true,
        picture: ''
      },
      IsDeleted: false,
      isLocked: false,
      IsSuspended: false,
      modifiedDate: null
    };
  }
}
