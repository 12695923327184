import { Component, OnInit } from '@angular/core';
import { IUser } from '../../../models/DbModels/IUser';
import { EmployeeTitle } from '../../../general/enums';
import { Router } from '@angular/router';
import { IAppStore } from '../../../state/IAppStore';
import { Store } from '@ngrx/store';
import { userNewFName, userNewLName, userNewDOB, userNewEmail, userNewPhone, userNewTitle, userNewNote } from '../../../state/actions/user-actions';
//import Selectr from 'mobius1-selectr';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.css']
})
export class UserNewComponent implements OnInit {

  public newUser: IUser;
  public options: EmployeeTitle[];

  public employeeTitleType: typeof EmployeeTitle = EmployeeTitle;

  public selectedTitle: any;

  constructor(private store: Store<{ store: IAppStore }> ) { }

  ngOnInit(): void {
    this.options = [EmployeeTitle.Admin, EmployeeTitle.Owner, EmployeeTitle.Master, EmployeeTitle.Employee];
    this.newUser = {
      userId: '',
      email: '',
      password: '',
      createdDate: null,
      employee: {
        employeeId: '',
        firstName: '',
        lastName: '',
        title: EmployeeTitle.None,
        notes: '',
        phone: '',
        birthDate: null,
        hireDate: null,
        isCurrentlyEmployed: true,
        picture: ''
      },
      IsDeleted: false,
      isLocked: false,
      IsSuspended: false,
      modifiedDate: null
    };
  }

  public selectfNameChange(event) {
    this.newUser.employee.firstName = event;
    this.store.dispatch(userNewFName({ payload: { fName: event } }));
  }
  public selectlNameChange(event) {
    this.newUser.employee.lastName = event;
    this.store.dispatch(userNewLName({ payload: { lName: event } }));
  }
  public selectDOBChange(event) {
    this.newUser.employee.birthDate = event;
    this.store.dispatch(userNewDOB({ payload: { DOB: event } }));
  }
  public selectEmailChange(event) {
    this.newUser.email = event;
    this.store.dispatch(userNewEmail({ payload: { email: event } }));
  }
  public selectPhoneChange(event) {
    this.newUser.employee.phone = event;
    this.store.dispatch(userNewPhone({ payload: { phone: event } }));
  }
  public selectTitleChange(event) {
    console.log(event);
    this.newUser.employee.title = +event;
    this.store.dispatch(userNewTitle({ payload: { title: +event } }));
  }
  public selectNotesChange(event) {
    this.newUser.employee.notes = event;
    this.store.dispatch(userNewNote({ payload: { note: event } }));
  }


}
