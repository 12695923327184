import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ILogComplete } from '../../models/supportive-models/ILogComplete';
import { DEBUG } from '../../general/contants';
import { environment } from '../../../environments/environment';

@Injectable()
export class LogService {

  constructor(private http: HttpClient) { }

  public getLogs(clientId:string, page: number, count: number) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };

    return this.http.get(api + 'Log/all/' + clientId + '/' + page + '/' + count, httpOptions);
  }

  public saveLog(newLog: ILogComplete) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };
    return this.http.post(api + 'Log', newLog, httpOptions);
  }

  public completeRemainderLog(logId: string) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };
    return this.http.put(api + 'Log/complete/'+logId, httpOptions);
  }
}
