import { Injectable } from '@angular/core';
import { CanActivate, Router, Route } from '@angular/router';
import { Observable, of, Subscription, observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IUser } from '../models/DbModels/IUser';
import { environment } from '../../environments/environment';
import { DEBUG } from '../general/contants';

@Injectable()
export class RegisterService {

  constructor(private http: HttpClient) {  }

  ngOnInit() { }
  ngOnDestroy() { }

  public registerNewUser(newUser: IUser) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };
    return this.http.post(api + 'user', newUser, httpOptions);
  }
}
