import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DEBUG } from '../../general/contants';
import { environment } from '../../../environments/environment';
import { IClient } from '../../models/dbModels/IClient';

@Injectable()
export class ClientService {

  constructor(private http: HttpClient) { }

  public getClients(page: number, count:number) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };

    return this.http.get(api + 'Client/admin/'+ page+'/'+count, httpOptions);
  }

  public getClientsByUser(userId: string, page:number, count: number) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };

    return this.http.get(api + 'Client/' + userId+'/'+page+'/'+count, httpOptions);
  }

  public saveNewClient(newClient: IClient) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };
    console.log(newClient);
    return this.http.post(api + 'Client', newClient, httpOptions);
  }
}
