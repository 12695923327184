import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { LoginService } from './services/login-service';

import { environment } from '../environments/environment';
import { AppStateService } from './services/state/app-state-service';
import { ProspectsNewComponent } from './pages/prospects/prospects-new/prospects-new.component';
import { ClientNewComponent } from './pages/clients/client-new/client-new.component';
import { UserNewComponent } from './pages/users/user-new/user-new.component';
import { UserService } from './services/user/user-service';
import { RegisterService } from './services/register-service';
import { ClientsComponent } from './pages/clients/clients.component';
import { UsersComponent } from './pages/users/users.component';
import { ProspectsComponent } from './pages/prospects/prospects.component';

//Home components
import { ContactComponent } from './pages/customerFacing/contact/contact.component';
import { AboutComponent } from './pages/customerFacing/about/about.component';
import { AgentsBrokersComponent } from './pages/customerFacing/agents-brokers/agents-brokers.component';
import { CareersComponent } from './pages/customerFacing/careers/careers.component';

//spinner
import { NgxSpinnerModule } from "ngx-spinner";

//auth guard
import { AuthGuard } from './security/auth-guard';

//redux
import { StoreModule } from '@ngrx/store';
import { StateManager } from './state/state-manager';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

//font awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//ng-boostrap modal
import { NgbModule, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from './services/client/client-service';
import { ClientViewComponent } from './pages/clients/client-view/client-view.component';

//primeng
import { CalendarModule } from 'primeng/calendar';
import { AuthGuardService } from './services/security/auth-guard-service';
import { LogService } from './services/log/log-service';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    NgxSpinnerModule,
    StoreModule.forRoot({ store: StateManager.storeReducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      //logOnly: environment.production
    }),
    FontAwesomeModule,
    CalendarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ClientsComponent,
    UsersComponent,
    ProspectsComponent,
    ProspectsNewComponent,
    ClientNewComponent,
    UserNewComponent,
    ClientViewComponent,
  ],
  providers: [
    AuthGuard, AuthGuardService, LoginService, AppStateService, RegisterService, UserService, ClientService,
    NgbModalConfig, NgbModal, LogService],
  bootstrap: [AppComponent]
})
export class AppModule { }


