import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LogsComponent } from './logs/logs.component';
import { FormsModule } from '@angular/forms';
import { PersonalLineComponent } from './personal-line/personal-line.component';
import { CommercialLineComponent } from './commercial-line/commercial-line.component';

//primeng
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ChatComponent } from './chat/chat.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    DropdownModule,
    FormsModule,
    CalendarModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    LogsComponent,
    PersonalLineComponent,
    CommercialLineComponent,
    ChatComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    LogsComponent,
    PersonalLineComponent,
    CommercialLineComponent,
    ChatComponent
  ]
})
export class ComponentsModule { }
