import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppStore } from '../../state/IAppStore';
import { ILog } from '../../models/dbModels/ILog';
import { Observable } from 'rxjs';
import { addLog, loadingLogs, updateLogRemaiderCompleteDate } from '../../state/actions/client/logs/log-actions';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPolicy } from '../../models/dbModels/IPolicy';
import { IUser } from '../../models/DbModels/IUser';
import { IClient } from '../../models/dbModels/IClient';
import { ILogComplete } from '../../models/supportive-models/ILogComplete';
import { BusinessType, Gender, Relationship, EmployeeTitle } from '../../general/enums';
import { LogService } from '../../services/log/log-service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  public newLog: ILog;
  public newLogComplete: ILogComplete;
  public logs: ILogComplete[];
  public tempPolicies: IPolicy[];
  public selectedUser: IUser;
  public currentUser: IUser;
  public currentClient: IClient;
  public error: string;
  private templateLog: ILogComplete;
  public date: Date;

  public logs$: Observable<ILogComplete[]>;
  public users$: Observable<IUser[]>;
  public currentClient$: Observable<IClient>;
  public currentUser$: Observable<IUser>;

  constructor(private store: Store<{ store: IAppStore }>, config: NgbModalConfig, private modalService: NgbModal, private logService: LogService) {
    this.logs$ = store.select(x => x.store.logs);
    this.users$ = store.select(x => x.store.users);
    this.currentClient$ = store.select(x => x.store.currentClient);
    this.currentUser$ = store.select(x => x.store.currentUser);

    //modal settings
    config.backdrop = 'static';
    config.keyboard = false;
    this.date = new Date();
    
  }

  ngOnInit(): void {
    this.init();
    this.logs$.subscribe(logs => this.logs = logs);
    this.currentClient$.subscribe(client => this.currentClient = client);
    this.currentUser$.subscribe(user => {
      this.currentUser = user;

      this.logService.getLogs(this.currentClient.clientId, 0, 10).subscribe(res => {
        let result: any = res;
        if (result.success) {
          this.store.dispatch(loadingLogs({ payload: { loadingLogs: result.item } }));
        }
      });
    });    
  }

  public completeRemainder(logId: string) {
    this.logService.completeRemainderLog(logId).subscribe(res => {
      let result: any = res;

      if (result.success) {
        this.store.dispatch(updateLogRemaiderCompleteDate({ payload: { completeDate: result.item, logId: logId } }))
      }
    });
  }

  public showAddModal(content) {
    this.newLogComplete = { ...this.templateLog }
    this.modalService.open(content, { size: 'xl' });
  }

  public saveLog() {
    this.newLogComplete.client = this.currentClient;
    this.newLogComplete.createdBy = this.currentUser;
    if (this.newLogComplete.reminderDate !== null || this.newLogComplete.reminderDate !== undefined) {
      this.newLogComplete.hasReminder = true;
    }
    this.logService.saveLog(this.newLogComplete).subscribe(res => {
      let result: any = res;
      if (result.success) {
        this.store.dispatch(addLog({ payload: { newLog: { ...result.item } } }));
        //this.newLogComplete = { ...this.templateLog};
        this.modalService.dismissAll('Saved log');
      } else {
        this.error = result.error.Message;
      }
    });
  
  }

  public viewLog(content: any, index: number) {
    this.newLogComplete.client = this.logs[index].client;
    this.newLogComplete.created = this.logs[index].created;
    this.newLogComplete.createdBy = this.logs[index].createdBy;
    this.newLogComplete.deleted = this.logs[index].deleted;
    this.newLogComplete.deletedBy = this.logs[index].deletedBy;
    this.newLogComplete.hasReminder = this.logs[index].hasReminder;
    this.newLogComplete.logId = this.logs[index].logId;
    this.newLogComplete.message = this.logs[index].message;
    this.newLogComplete.policy = this.logs[index].policy;
    if (this.logs[index].reminderDate === null) { this.newLogComplete.reminderDate = null } else { this.newLogComplete.reminderDate = new Date(this.logs[index].reminderDate) };
    if (this.logs[index].reminderCompletedDate === null) { this.newLogComplete.reminderCompletedDate = null } else { this.newLogComplete.reminderCompletedDate = new Date(this.logs[index].reminderCompletedDate) };
    this.newLogComplete.remindUser = this.logs[index].remindUser;

    this.modalService.open(content, { size: 'xl' });
  }

  public clearLog() {
    //this.newLog = { ...this.templateLog };
  }
  //init
  private init() {
    this.logs = [];
    this.tempPolicies = [{ policyId: '1' }, { policyId: '2' }];
    this.templateLog = {
      client: {
        additionalInsureds: [],
        business: {
          businessAddress: {
            city: '', state: '', street: '', zip: ''
          },
          businessId: '',
          businessName: '',
          businessType: BusinessType.Corp,
          ein: '',
          mailingAddress: {
            city: '', state: '', street: '', zip: ''
          }
        },
        clientId: '',
        contacts: [],
        created: new Date(),
        csr: {
          createdDate: new Date(),
          email: '',
          employee: {
            birthDate: new Date(),
            employeeId: '',
            firstName: '',
            hireDate: new Date(),
            isCurrentlyEmployed: false,
            lastName: '',
            notes: '',
            phone: '',
            picture: '',
            title: EmployeeTitle.Admin
          },
          IsDeleted: false,
          isLocked: false,
          IsSuspended: false,
          password: '',
          userId: '',
          modifiedDate: new Date()
        },
        dob: new Date(),
        driverLic: '',
        email: '',
        firstName: '',
        gender: Gender.Female,
        lastName: '',
        mailingAddress: {
          city: '', state: '', street: '', zip: ''
        },
        occupation: '',
        phone: '',
        producer: {
          createdDate: new Date(),
          email: '',
          employee: {
            birthDate: new Date(),
            employeeId: '',
            firstName: '',
            hireDate: new Date(),
            isCurrentlyEmployed: false,
            lastName: '',
            notes: '',
            phone: '',
            picture: '',
            title: EmployeeTitle.Admin
          },
          IsDeleted: false,
          isLocked: false,
          IsSuspended: false,
          password: '',
          userId: '',
          modifiedDate: new Date()
        },
        relationship: Relationship.Child,
        residenceAddress: {
          city: '', state: '', street: '', zip: ''
        },
        source: '',
        ssn: '',
        deleted: null,
        modified: null
      },
      created: new Date(),
      createdBy: {
        createdDate: new Date(),
        email: '',
        employee: {
          birthDate: new Date(),
          employeeId: '',
          firstName: '',
          hireDate: new Date(),
          isCurrentlyEmployed: false,
          lastName: '',
          notes: '',
          phone: '',
          picture: '',
          title: EmployeeTitle.Admin
        },
        IsDeleted: false,
        isLocked: false,
        IsSuspended: false,
        password: '',
        userId: '',
        modifiedDate: new Date()
      },
      hasReminder: false,
      logId: '',
      message: '',
      policy: {
        policyId: ''
      },
      deleted: null,
      deletedBy: null,
      reminderDate: null,
      reminderCompletedDate: null,
      remindUser: null
    };

    this.newLogComplete = { ...this.templateLog };
  }

  //events
  public policyChange(event) {
    this.newLogComplete.policy = event;
  }

  public remainderUserChange(event) {
    this.newLogComplete.remindUser = event;
  }

  public remainderDateChange(event) {
    this.newLogComplete.reminderDate = event;
  }

  public logMessageChange(event) {
    this.newLogComplete.message = event;
  }
}
