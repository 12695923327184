import { Injectable } from '@angular/core';
import { CanActivate, Router, Route } from '@angular/router';
import { Observable, of, Subscription, observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ILoginCredentials } from '../models/ILoginCredentials';
import { DEBUG } from '../general/contants';

@Injectable()
export class LoginService {

  constructor(private http: HttpClient) { }

  public validateCredentials(loginCredentials: ILoginCredentials) {
    let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    let api: string = environment.apiHost + 'api/v1/';

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      ),
      params: new HttpParams()
    };

    return this.http.post(api + 'CredentialsValidator', loginCredentials, httpOptions);
  }

  public logout() {
  }

  ngOnDestroy() {

  }
}
