import { Injectable } from '@angular/core';
import { CanActivate, Router, Route, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { IAppStore } from '../state/IAppStore';
import { Store } from '@ngrx/store';
import { IUser } from '../models/DbModels/IUser';
import { map } from 'rxjs/operators';
import { AppStateService } from '../services/state/app-state-service';
import { resetStore } from '../state/actions/state-actions';
import { AuthGuardService } from '../services/security/auth-guard-service';

@Injectable()
export class AuthGuard implements CanActivate {


  public currentUser: IUser;
  public currentUser$: Observable<IUser>;

  private currentUserSubscription: Subscription;

  constructor(private route: Router, private authGuardService: AuthGuardService, private store: Store<{ store: IAppStore }>, private appStateService: AppStateService) {
    this.currentUser$ = store.select(x => x.store.currentUser);
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.currentUserSubscription != undefined) { this.currentUserSubscription.unsubscribe(); }
  }

  canActivate(route: ActivatedRouteSnapshot, ): Observable<boolean> {
    let roles = route.data.roles as Array<string>;
    //console.log(roles);
    return this.authGuardService.validateToken();  
  }

  //canActivate(): Observable<boolean> {
  //}


  public logout() {
    let stateId = localStorage.getItem('stateId');
    let userId = localStorage.getItem('userId');
    if (stateId !== null && stateId !== undefined && userId !== null && userId !== undefined) {
      this.appStateService.deleteState(stateId, userId).subscribe(deleteResult => {
        //if (localStorage.getItem('rmb') == 'true') {
        //  var tempUsrHolder = localStorage.getItem('usr');
        //  var tempRememberHolder = localStorage.getItem('rmb');
        //  localStorage.clear();
        //  localStorage.setItem('usr', tempUsrHolder);
        //  localStorage.setItem('rmb', tempRememberHolder);
        //} else {
        localStorage.clear();
        //}
      });


      this.store.dispatch(resetStore());
      this.route.navigate(['/login']);
    }
  }

  public hasValidState(): Observable<boolean> {
    return new Observable<boolean>();
  }
}
