import { createAction, props } from "@ngrx/store";
import { IClient } from "../../../models/dbModels/IClient";
import { Gender, Relationship } from "../../../general/enums";
import { IUser } from "../../../models/DbModels/IUser";
import { IAdditionalInsured } from "../../../models/supportive-models/IAdditionalInsured";
import { IContact } from "../../../models/supportive-models/IContact";
import { IAddress } from "../../../models/supportive-models/IAddress";

//select client
export const clientSelect = createAction('[Selected client] select client', props<{ payload: { clientIndex: number; } }>());

//update current client
export const clientUpdate = createAction('[Update client] update client', props<{ payload: { client: IClient; } }>());

export const clientAddClient = createAction('[Get client] Add client', props < { payload: { client: IClient } }>() );
export const clientSetClients = createAction('[Get client] Get clients', props<{ payload: { clients: IClient[] } }>());
export const clientUpdateFName = createAction('[Update client] update client firstName', props<{ payload: { firstName: string } }>());
export const clientUpdateLName = createAction('[Update client] update client lastName', props<{ payload: { lastName: string; } }>());
export const clientUpdatePhone = createAction('[Update client] update client phone', props<{ payload: { phone: string; } }>());
export const clientUpdateEmail = createAction('[Update client] update client email', props<{ payload: { email: string; } }>());
export const clientUpdateResidentialStreet = createAction('[Update client] update client residential street', props<{ payload: { street: string; } }>());
export const clientUpdateResidentialCity = createAction('[Update client] update client residential city', props<{ payload: { city: string; } }>());
export const clientUpdateResidentialState = createAction('[Update client] update client residential state', props<{ payload: { state: string; } }>());
export const clientUpdateResidentialZip = createAction('[Update client] update client residential zip', props<{ payload: { zip: string; } }>());
export const clientUpdateMailingStreet = createAction('[Update client] update client mailing street', props<{ payload: { street: string; } }>());
export const clientUpdateMailingCity = createAction('[Update client] update client mailing city', props<{ payload: { city: string; } }>());
export const clientUpdateMailingState = createAction('[Update client] update client mailing state', props<{ payload: { state: string; } }>());
export const clientUpdateMailingZip = createAction('[Update client] update client mailing zip', props<{ payload: { zip: string; } }>());
export const clientUpdateGender = createAction('[Update client] update client gender', props<{ payload: { gender: Gender; } }>());
export const clientUpdateRelationship = createAction('[Update client] update client relationship', props<{ payload: { relationship: Relationship; } }>());
export const clientUpdateOccupation = createAction('[Update client] update client occupation' , props<{ payload: { occupation: string; } }>());
export const clientUpdateSsn = createAction('[Update client] update client ssn', props<{ payload: { ssn: string; } }>());
export const clientUpdateDriverLicense = createAction('[Update client] update client driverLicense', props<{ payload: { driverLicense: string; } }>());
export const clientUpdateProducer = createAction('[Update client] update client producer', props<{ payload: { producer: IUser; } }>());
export const clientUpdateCsr = createAction('[Update client] update client csr', props<{ payload: { csr: IUser; } }>());
export const clientUpdateSource = createAction('[Update client] update client source', props<{ payload: { source: string; } }>());
export const clientUpdateAdditionalInsureds = createAction('[Update client] update client additional insureds', props<{ payload: { additionalInsureds: IAdditionalInsured[]; } }>());
export const clientAddContact = createAction('[Update client] update client add new contact', props<{ payload: { newContact: IContact; } }>());
export const clientRemoveContact = createAction('[Update client] update client remove contact', props<{ payload: { contactToRemoveIndex: number; } }>());
export const clientUpdateMailing = createAction('[Update client] update client update mailing', props<{ payload: { mailingAddress: IAddress; } }>());
export const clientUpdateDob = createAction('[Update client] update client update dob', props<{ payload: { dob: Date; } }>());

//add additional insureds
export const clientAddAdditionalInsured = createAction('[Update client] Add client additional insured', props<{ payload: { additionalInsureds: IAdditionalInsured; } }>());
//update additional insureds
export const clientUpdateAdditionalInsured = createAction('[Update client] update client additional insured', props<{ payload: { index: number, additionalInsureds: IAdditionalInsured; } }>());
export const clientAdditionalInsuredUpdateFName = createAction('[Update client] update client additional insured firstName', props<{ payload: { index:number , firstName: string } }>());
export const clientAdditionalInsuredUpdateLName = createAction('[Update client] update client additional insured lastName', props<{ payload: { index: number,lastName: string; } }>());
export const clientAdditionalInsuredUpdatePhone = createAction('[Update client] update client additional insured phone', props<{ payload: { index: number,phone: string; } }>());
export const clientAdditionalInsuredUpdateEmail = createAction('[Update client] update client additional insured email', props<{ payload: { index: number,email: string; } }>());
export const clientAdditionalInsuredUpdateAddress = createAction('[Update client] update client additional insured address', props<{ payload: { index: number,address: string; } }>());
export const clientAdditionalInsuredUpdateCity = createAction('[Update client] update client additional insured city', props<{ payload: { index: number,city: string; } }>());
export const clientAdditionalInsuredUpdateState = createAction('[Update client] update client additional insured state', props<{ payload: { index: number,state: string; } }>());
export const clientAdditionalInsuredUpdateZip = createAction('[Update client] update client additional insured zip', props<{ payload: { index: number,zip: string; } }>());
export const clientAdditionalInsuredUpdateGender = createAction('[Update client] update client additional insured gender', props<{ payload: { index: number,gender: Gender; } }>());
export const clientAdditionalInsuredUpdateRelationship = createAction('[Update client] update client additional insured relationship', props<{ payload: { index: number,relationship: Relationship; } }>());
export const clientAdditionalInsuredUpdateOccupation = createAction('[Update client] update client additional insured occupation', props<{ payload: { index: number,occupation: string; } }>());
export const clientAdditionalInsuredUpdateSsn = createAction('[Update client] update client additional insured ssn', props<{ payload: { index: number,ssn: string; } }>());
export const clientAdditionalInsuredUpdateDriverLicense = createAction('[Update client] update client additional insured driverLicense', props<{ payload: { index: number,driverLicense: string; } }>());
export const clientAdditionalInsuredDob = createAction('[Update client] update client additional insured dob', props<{ payload: { index: number, dob: Date; } }>());
