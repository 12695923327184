import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppStore, AppStoreClass } from './state/IAppStore';
import { AppStateService } from './services/state/app-state-service';
import { Router } from '@angular/router';
import { IUserAppState } from './models/state-model/IUserAppState';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'argon-dashboard-angular';

  constructor(private store: Store<{ store: IAppStore }>, private appStateService: AppStateService, private router : Router) {
    //console.log('At app component');
    let a = new AppStoreClass(this.store, this.appStateService, this.router);
  }

  ngOnInit() { }
}
