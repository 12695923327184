import { Store } from "@ngrx/store";
import { AppStateService } from "../services/state/app-state-service";
import { myInit } from "./actions/state-actions";
import { IUser } from "../models/DbModels/IUser";
import { EmployeeTitle, Gender, Relationship, BusinessType } from "../general/enums";
import { IClient } from "../models/dbModels/IClient";
import { Router } from "@angular/router";
import { IBusiness } from "../models/dbModels/IBusiness";
import { ILog } from "../models/dbModels/ILog";
import { ILogComplete } from "../models/supportive-models/ILogComplete";

export interface IAppStore {
  currentUser: IUser,
  newUser: IUser,
  currentClient: IClient,
  clients: IClient[],
  userToken: string,
  business: IBusiness,
  isBusiness: boolean,
  users: IUser[],
  logs: ILogComplete[]
}

/********************************************************************************************************/
/************************************************STORE***************************************************/
/********************************************************************************************************/
//TODO: Need to save store either in localStorage or DB since the store gets init on page refresh
export const initialAppState: IAppStore = {
  userToken: '',
  currentUser: {
    userId: '',
    email: '',
    password: '',
    createdDate: new Date(),
    employee: {
      employeeId: '',
      firstName: '',
      lastName: '',
      title: EmployeeTitle.None,
      notes: '',
      phone: '',
      birthDate: null,
      hireDate: null,
      isCurrentlyEmployed: true,
      picture: ''
    },
    IsDeleted: false,
    isLocked: false,
    IsSuspended: false,
    modifiedDate: null
  },
  newUser: {
    userId: '',
    email: '',
    password: '',
    createdDate: new Date(),
    employee: {
      employeeId: '',
      firstName: '',
      lastName: '',
      title: EmployeeTitle.None,
      notes: '',
      phone: '',
      birthDate: null,
      hireDate: null,
      isCurrentlyEmployed: true,
      picture: ''
    },
    IsDeleted: false,
    isLocked: false,
    IsSuspended: false,
    modifiedDate: null
  },
  clients: [],
  currentClient: {
    clientId: '',
    firstName: '',
    lastName: '',
    dob:null,
    phone: '',
    email: '',
    business: {
      businessAddress: {
        city: '',
        state: '',
        street: '',
        zip:''
      },
      businessId: '',
      businessName: '',
      businessType: BusinessType.Corp,
      ein: '',
      mailingAddress: {
        city: '',
        state: '',
        street: '',
        zip: ''
      }
    },
    mailingAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    residenceAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    driverLic: '',
    gender: Gender.Male,
    occupation: '',
    relationship: Relationship.Insured,
    ssn: '',
    producer:
    {
      userId: '',
      email: '',
      employee: {
        employeeId: '',
        firstName: '',
        lastName: '',
        title: EmployeeTitle.Owner,
        notes: '',
        phone: '',
        birthDate: null,
        hireDate: null,
        isCurrentlyEmployed: true,
        picture: ''
      },
      IsDeleted: false,
      isLocked: false,
      IsSuspended: false,
      password: '',
      createdDate: new Date(),
      modifiedDate: null
    },
    csr:
    {
      userId: '',
      email: '',
      employee: {
        employeeId: '',
        firstName: '',
        lastName: '',
        title: EmployeeTitle.Owner,
        notes: '',
        phone: '',
        birthDate: null,
        hireDate: null,
        isCurrentlyEmployed: true,
        picture: ''
      },
      IsDeleted: false,
      isLocked: false,
      IsSuspended: false,
      password: '',
      createdDate: new Date(),
      modifiedDate: null
    },
    additionalInsureds:
      [
        {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          driverLic: '',
          gender: Gender.Male,
          occupation: '',
          relationship: Relationship.Insured,
          ssn: ''
        }
      ],
    source: '',
    contacts: [],
    created: new Date(),
    deleted: null,
    modified: null
  },
  business: {
    businessId: '',
    businessName: '',
    businessType: BusinessType.Corp,
    businessAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    mailingAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    ein: ''
  },
  isBusiness: false,
  users: [],
  logs: []
}

export const resetAppState: IAppStore = {
  userToken: '',
  currentUser: {
    userId: '',
    email: '',
    password: '',
    createdDate: new Date(),
    employee: {
      employeeId: '',
      firstName: '',
      lastName: '',
      title: EmployeeTitle.None,
      notes: '',
      phone: '',
      birthDate: null,
      hireDate: null,
      isCurrentlyEmployed: true,
      picture: ''
    },
    IsDeleted: false,
    isLocked: false,
    IsSuspended: false,
    modifiedDate: null
  },
  newUser: {
    userId: '',
    email: '',
    password: '',
    createdDate: new Date(),
    employee: {
      employeeId: '',
      firstName: '',
      lastName: '',
      title: EmployeeTitle.None,
      notes: '',
      phone: '',
      birthDate: null,
      hireDate: null,
      isCurrentlyEmployed: true,
      picture: ''
    },
    IsDeleted: false,
    isLocked: false,
    IsSuspended: false,
    modifiedDate: null
  },
  clients:[],
  currentClient: {
    clientId: '',
    firstName: '',
    lastName: '',
    dob:null,
    phone: '',
    email: '',
    business: {
      businessAddress: {
        city: '',
        state: '',
        street: '',
        zip: ''
      },
      businessId: '',
      businessName: '',
      businessType: BusinessType.Corp,
      ein: '',
      mailingAddress: {
        city: '',
        state: '',
        street: '',
        zip: ''
      }
    },
    mailingAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    residenceAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    driverLic: '',
    gender: Gender.Male,
    occupation: '',
    relationship: Relationship.Insured,
    ssn: '',
    producer:
    {
      userId: '',
      email: '',
      employee: {
        employeeId: '',
        firstName: '',
        lastName: '',
        title: EmployeeTitle.Owner,
        notes: '',
        phone: '',
        birthDate: null,
        hireDate: null,
        isCurrentlyEmployed: true,
        picture: ''
      },
      IsDeleted: false,
      isLocked: false,
      IsSuspended: false,
      password: '',
      createdDate: new Date(),
      modifiedDate: null
    },
    csr:
    {
      userId: '',
      email: '',
      employee: {
        employeeId: '',
        firstName: '',
        lastName: '',
        title: EmployeeTitle.Owner,
        notes: '',
        phone: '',
        birthDate: null,
        hireDate: null,
        isCurrentlyEmployed: true,
        picture: ''
      },
      IsDeleted: false,
      isLocked: false,
      IsSuspended: false,
      password: '',
      createdDate: new Date(),
      modifiedDate: null
    },
    additionalInsureds:
      [
        {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          driverLic: '',
          gender: Gender.Male,
          occupation: '',
          relationship: Relationship.Insured,
          ssn: ''
        }
      ],
    source: '',
    contacts: [],
    created: new Date(),
    deleted: null,
    modified:null
  },
  business: {
    businessId: '',
    businessName: '',
    businessType: BusinessType.Corp,
    businessAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    mailingAddress: {
      street: '', city: '',
      state: '', zip: ''
    },
    ein: ''
  },
  isBusiness: false,
  users: [],
  logs: []
}

export class AppStoreClass {
  public stateId: string;

  constructor(private store: Store<{ store: IAppStore }>, private appStateService: AppStateService, private router: Router) {
    //get user Id from local storage
    let userId = localStorage.getItem("userId");

    //make sure we have an userId before calling API
    if (userId === undefined || userId === null || userId === '') {
      this.router.navigateByUrl('/wc/admin/login');
    }
    else {
      this.appStateService.getState(userId).subscribe(stateResult => {
        let res: any = stateResult;
        if (res.success) {
          console.log(res);
          let state: IAppStore = JSON.parse(res.item.store);
          this.store.dispatch(myInit({ payload: { store: state } }));
          //
        }
      });
    }
  }
}
