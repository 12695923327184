import { createAction, props } from "@ngrx/store";
import { IAppStore } from "../IAppStore";
import { EmployeeTitle } from "../../general/enums";
import { IUser } from "../../models/DbModels/IUser";

//
export const usersUpdate = createAction('[Update Users] update users', props<{ payload: { users: IUser[]; } }>());

//update current user
export const userUpdate = createAction('[Update User] update user', props<{ payload: { user: IUser; } }>());
export const userUpdateFName = createAction('[Update User] update user first name', props<{ payload: { fName: string; } }>());
export const userUpdateLName = createAction('[Update User] update user last name', props<{ payload: { lName: string; } }>());
export const userUpdateDOB = createAction('[Update User] update user DOB', props<{ payload: { DOB: Date; } }>());
export const userUpdateEmail = createAction('[Update User] update user email', props<{ payload: { email: string; } }>());
export const userUpdatePhone = createAction('[Update User] update user phone', props<{ payload: { phone: string; } }>());
export const userUpdateTitle = createAction('[Update User] update user title', props<{ payload: { title: EmployeeTitle; } }>());
export const userUpdateNote = createAction('[Update User] update user note', props<{ payload: { note: string; } }>());

//add new user data
export const userNewFName = createAction('[New User] add new user first name', props<{ payload: { fName: string; } }>());
export const userNewLName = createAction('[New User] add new user last name', props<{ payload: { lName: string; } }>());
export const userNewDOB = createAction('[New User] add new user DOB', props<{ payload: { DOB: Date; } }>());
export const userNewEmail = createAction('[New User] add new user email', props<{ payload: { email: string; } }>());
export const userNewPhone = createAction('[New User] add new user phone', props<{ payload: { phone: string; } }>());
export const userNewTitle = createAction('[New User] add new user title', props<{ payload: { title: EmployeeTitle; } }>());
export const userNewNote = createAction('[New User] add new user note', props<{ payload: { note: string; } }>());

//token

export const userToken = createAction('[New User] add new user token', props<{ payload: { token: string; } }>());
